export function initGalleries() {
  const galleries = document.querySelectorAll(".gallery")

  galleries.forEach((gallery) => {
    const imagesContainer = gallery.querySelector(".gallery__contents")
    const thumbs = gallery.querySelectorAll("button")
    const figures = gallery.querySelectorAll("figure")

    const figureObserver = new ResizeObserver((figures) => {
      figures.forEach((figure) => {
        imagesContainer.style.height = `${figure.contentRect?.height}px`
      })
    })

    function activate(id) {
      thumbs.forEach((thumb) => {
        thumb.classList.toggle("active", thumb.dataset.id === id)
      })
      figures.forEach((figure) => {
        figure.classList.toggle("active", figure.dataset.id === id)
        if (figure.dataset.id === id) {
          figureObserver.observe(figure)
        } else {
          figureObserver.unobserve(figure)
        }
      })
    }

    thumbs.forEach((thumb) => {
      thumb.onclick = () => {
        activate(thumb.dataset.id)
      }
    })

    if (thumbs[0]) activate(thumbs[0].dataset.id)
  })
}
